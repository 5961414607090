

function throwUIError(text) {
    alert(text);
}

let currentLoader = false;

function toggleLoader() {
    if (currentLoader) {
        document.querySelector('.login-icon').classList.remove('dn');
        currentLoader = true;
    } else {
        document.querySelector('.login-icon').classList.add('dn');
        currentLoader = false;
    }
}

function loginAction() {
    new Promise(() => {
        setTimeout(() => {
            toggleLoader();
        }, 1)
    })
    let uname = document.querySelector('#uname').value;
    let password = document.querySelector('#pw').value;


    let securePassword = sha3_512(password);

    let pcBody = {
        login: uname,
        password: securePassword
    }
    let respo = apiJsonPost('login', pcBody);
    if (respo.success) {
        localStorage.setItem('AppAccessToken', respo.token);
        updateLCCrash(respo.token);
        updateLCPrefs(respo.token);
        updateLCCompany(respo.token);
        window.location.href = '/';

    } else {
        throwUIError(`Kullanıcı Adı veya Şifre yanlış. Lütfen bilgileri kontrol edin ve tekrardan deneyin.`);
    }



    toggleLoader();
}
document.querySelector('.login-btn').addEventListener('click', () => {
    loginAction();
})
document.querySelector('#pw').addEventListener("keyup", function(event) {
    if (event.keyCode === 13) {
        loginAction();
    }
});
document.querySelector('#pw').addEventListener("keyup", function() {
    if (document.querySelector('#pw').value === 'tysdestek22') {
        location.href = '/frames/static/diags.html';
    }
});
